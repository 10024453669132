import React from "react";

import logo1 from "../../assets/images/logo/logo3.svg";

import Icons from "../../assets/icons";
import Input from "../../common/input";
import Button from "../../common/button";
import { Link } from "react-router-dom";
import useNewsLetter from "../../hooks/useNewsLetter";
import Loader from "../../common/loader";

const MenuItem = ({ onClick, link, text }) => {
  return (
    <li onClick={onClick}>
      {link ? (
        <Link
          to={link}
          className="block capitalize text-sm !leading-[16.8px] tracking-[1.68px] text-primary-50"
        >
          {text}
        </Link>
      ) : (
        <span className="cursor-pointer block capitalize text-sm !leading-[16.8px] tracking-[1.68px] text-primary-50">
          {text}
        </span>
      )}
    </li>
  );
};

const CompanyMenuItems = () => {
  return (
    <ul className="flex flex-col gap-8">
      <MenuItem link="/about" text={"About us"} />
      <MenuItem link="/management" text={"Our Management Team"} />
      <MenuItem link="/code-of-conduct" text={"Code of Conduct"} />
      <MenuItem link="/purchase-process" text={"Gas purchase Process"} />
    </ul>
  );
};

const ServicesMenuItems = () => {
  return (
    <ul className="flex flex-col gap-8">
      <MenuItem link="/services" text={"What we offer"} />
      <MenuItem link="/initiatives" text={"GACN initiatives"} />
      <MenuItem link="/energy-transition" text={"GACN Energy Transition"} />
      <MenuItem link="/partnerships" text={"GACN Partnerships"} />
    </ul>
  );
};

const NewsMenuItems = () => {
  return (
    <ul className="flex flex-col gap-8">
      <MenuItem link="/news" text={"News update"} />
      <MenuItem link="/publications" text={"Publications"} />
      <MenuItem link="/gallery" text={"Gallery"} />
      {/* <MenuItem link="/branding" text={"Media & Branding"} /> */}
    </ul>
  );
};

const SupportMenuItems = ({ toggleContactModal }) => {
  return (
    <ul className="flex flex-col gap-8">
      <MenuItem link="/faq" text={"FAQ"} />
      <MenuItem onClick={toggleContactModal} text={"Contact us"} />
      <MenuItem link="/terms-and-conditions" text={"Terms & Conditions"} />
      {/* <MenuItem link="/whistleblower-policy" text={"Whistleblower Policy"} /> */}
    </ul>
  );
};

const Footertitle = ({ text }) => {
  return (
    <h4 className="uppercase font-semibold text-white text-sm tracking-[1.68px] pb-8">
      {text}
    </h4>
  );
};

const Divider = ({ styles }) => {
  return (
    <div
      className={`h-[1px] md:h-[213px] w-full md:w-[1px] bg-primary-800 ${styles}`}
    />
  );
};

const HorizontalDivider = ({ styles }) => {
  return <div className={`h-[1px] w-full bg-primary-800 ${styles}`} />;
};

const Footer = ({ toggleContactModal }) => {
  const {
    loading,
    email,
    emailError,
    isSuccessful,
    handleChange,
    handleSubmit,
  } = useNewsLetter();

  return (
    <footer id="footer" className="bg-primary-900 text-white">
      <div className="mx-auto container">
        <div className=" w-full py-20 flex flex-col gap-16">
          {isSuccessful ? (
            <div className="space-y-6 text-center">
              <p className="text-primary-75">Thanks for subscribing!</p>
              <p>You can unsubscribe anytime!</p>
            </div>
          ) : (
            <div className="flex flex-col gap-6 items-center justify-center">
              <p>Stay Updated with Happenings at GACN</p>
              <div className="flex justify-center flex-col md:flex-row gap-3 w-full">
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email address"
                  className="w-full md:w-[268px]"
                  value={email}
                  onChange={(e) => handleChange(e.target.value)}
                  errors={emailError}
                />

                <Button
                  disabled={!email || emailError}
                  onClick={handleSubmit}
                  className="w-full md:w-[178px]"
                >
                  {loading ? <Loader /> : "Subscribe"}
                </Button>
              </div>
            </div>
          )}
          <HorizontalDivider />
          <div className="flex justify-between flex-wrap md:flex-nowrap gap-10">
            <div className="">
              <Footertitle text="Company" />
              <CompanyMenuItems />
            </div>
            <Divider />

            <div className="">
              <Footertitle text="Services" />
              <ServicesMenuItems />
            </div>
            <Divider />

            <div className="">
              <Footertitle text="News & insight" />
              <NewsMenuItems />
            </div>
            <Divider />

            <div className="">
              <Footertitle text="Support" />
              <SupportMenuItems toggleContactModal={toggleContactModal} />
            </div>
          </div>
          <HorizontalDivider />
          <div className="space-y-6 max-w-[592px] mx-auto text-sm text-center text-primary-50">
            <p>
              The Gas Aggregator Company Nigeria Ltd/GTE (GACN) was incorporated
              in Nigeria on January 2010 for the purpose of stimulating growth
              of natural gas utilization in the Nigerian domestic market.
            </p>
            <p>
              <span className="text-primary-100">Address: </span> 1B Malcom
              Fraser Street Asokoro, Abuja, Nigeria
            </p>
            <p>
              <span className="text-primary-100">Call: </span>
              <a href="tel:02094605830">02094605830</a>,{" "}
              <span className="text-primary-100">Email: </span>
              <a href="mailto:info@gacn.com"> info@gacn.com</a>
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center px-2 md:px-8 py-6 bg-primary-50 rounded-lg gap-8 md:gap-2">
            <div className="flex items-center gap-6">
              <a
                href="https://facebook.com/people/Gas-Aggregation-Company-Nigeria-Ltd/100069027182947/"
                target="_blank"
                rel="noreferrer"
              >
                {Icons.ic_facebook}
              </a>
              <a
                className="w-8 h-8 bg-primary rounded-full flex items-center justify-center"
                href="https://x.com/Gacn_LtdGte"
                target="_blank"
                rel="noreferrer"
              >
                {Icons.ic_twitter}
              </a>
              <a
                href="https://www.instagram.com/gacn_ltdgte/"
                target="_blank"
                rel="noreferrer"
              >
                {Icons.ic_instagram}
              </a>
              <a
                href="https://www.linkedin.com/company/gas-aggregation-company-nigeria/"
                target="_blank"
                rel="noreferrer"
              >
                {Icons.ic_linkedin}
              </a>
            </div>

            <p className="text-sm font-bold text-grey-800 text-center md:text-left">
              © Copyright 2024 GACN All Rights Reserved.
            </p>
            <img className="" src={logo1} alt="logo" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
