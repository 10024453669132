import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import Disclaimer from "../components/disclaimer";

const DisclaimerPage = () => {
  return (
    <Layout>
      <InnerBanner
        title="Disclaimer"
        description="Last updated: January, 2025"
      />
      <Disclaimer />
    </Layout>
  );
};

export default DisclaimerPage;
