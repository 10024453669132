import React from "react";

const disclaimerData = [
  {
    title: "Confidentiality Disclaimer",
    description:
      "The content of this email is confidential and intended solely for the recipient specified in the message. Sharing any part of this message with third parties without the sender's written consent is strictly prohibited. If you have received this message in error, please reply to this email and delete it immediately to prevent future occurrences of such mistakes.",
  },
  {
    title: "Security Disclaimer",
    description:
      "GACN LTD/GTE prioritises the security of our communications. Significant efforts have been made to ensure this email is error and virus free. However, complete email security cannot be guaranteed. Despite our precautions, emails may become infected, intercepted, or corrupted. Recipients are advised to scan this email for threats using appropriate security software, as GACN LTD/GTE cannot accept liability for any damages arising from viewing this email's content.",
  },
  {
    title: "Contract Disclaimer",
    description:
      "This correspondence is for informational purposes only and does not constitute a legally binding contract. No employee of GACN LTD/GTE is authorised to enter into a binding agreement without explicit written approval from their designated supervisor. Any intention to enter into a legally binding agreement must be confirmed through the appropriate channels.",
  },
  {
    title: "Environmental Disclaimer",
    description:
      "GACN LTD/GTE is committed to fostering a sustainable environment. Please consider the environmental impact before printing this email.",
  },
  {
    title: "Employee Liability Disclaimer",
    description:
      "The views and opinions expressed in this email belong solely to the author and may not reflect the views and opinions of GACN LTD/GTE. Employees are prohibited from making defamatory statements or infringing upon any legal rights. GACN LTD/GTE disclaims liability for such statements, and employees are personally responsible for the content of their communications.",
  },
];

export default function Disclaimer() {
  return (
    <section className="pb-20 mt-[-34px] md:mt-[-80px]">
      <div className="container">
        <div className="bg-grey-50 border border-grey-200 px-6 py-12 md:p-12 space-y-2 max-w-[912px] mx-auto text-grey-600 text-base md:text-lg !leading-[32px]">
          {disclaimerData.map((terms) => (
            <div
              key={terms.title}
              className="py-8 last:pb-0 space-y-6 border-b last:border-none border-grey-300"
            >
              <h6 className="text-black text-lg md:text-xl font-semibold">
                {terms.title}{" "}
              </h6>

              <p className="">{terms.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
